#root .react-colorful {
  width: 100%;
}

.fontpicker__search {
  border: 0;
  color: white;
}

.fontpicker__search:focus {
  outline: unset;
  box-shadow: unset;
}

.fontpicker__popout {
  width: 101%;
  left: -1px;
}

.fontpicker__preview.font-preview-timesnewroman:after {
  content: "Times New Roman";
  font: 1em "Times New Roman", serif;
  position: relative;
  top: 4px;
  left: 9px;
}

.fontpicker__option .font-preview-timesnewroman:after {
  content: "Times New Roman";
  font: 1em "Times New Roman", serif;
  position: relative;
  top: 4px;
  left: 9px;
}